import { useEffect, lazy, Suspense } from 'react';

// Only import components needed for first render
import Header from './components/Header';
import Page0 from './pages/Page-0';

// Lazy load other components
const Dialog = lazy(() => import('./components/Dialog'));
const Footer = lazy(() => import('./components/Footer'));
const Page1 = lazy(() => import('./pages/Page-1'));
const Page2 = lazy(() => import('./pages/Page-2'));
const Page3 = lazy(() => import('./pages/Page-3'));
const Page4 = lazy(() => import('./pages/Page-4'));
const Page5 = lazy(() => import('./pages/Page-5'));

export default function Index() {
  useEffect(() => {
    function handleSpacePress(e: KeyboardEvent) {
      if (e.code === 'Space') {
        e.preventDefault();
      }
    }

    window.addEventListener('keydown', handleSpacePress);
    return () => window.removeEventListener('keydown', handleSpacePress);
  }, []);

  return (
    <div>
      <Suspense fallback={null}>
        <Dialog />
      </Suspense>
      <Header className="fixed top-0 z-50 h-20 w-full sm:h-10" />
      <div className="section snap-y snap-mandatory">
        <Page0 className="min-h-screen w-full snap-start pt-20" />
        <Suspense fallback={null}>
          <Page1 className="min-h-screen w-full snap-start pt-[100px]" />
          <Page2 className="min-h-screen w-full snap-start pt-[100px]" />
          <Page3 className="min-h-screen w-full snap-start pt-[100px]" />
          <Page4 className="min-h-screen w-full snap-start" />
          <div className="relative flex min-h-screen w-full snap-start flex-col justify-between gap-16 pt-[100px]">
            <Page5 />
            <Footer className="snap-start" />
          </div>
        </Suspense>
      </div>
    </div>
  );
}
