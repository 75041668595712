import './Index.scss'

export default function Demo() {
    return <div>
        <div className="page">
            <div>
                <img src='https://uploads.codesandbox.io/uploads/user/6480904d-d79b-484b-9f16-8d5a3eff77e3/JXQA-1.jpg'/>
                <h2>#001</h2>
            </div>
        </div>
        <div className="page">
            <div>
                <img src='https://uploads.codesandbox.io/uploads/user/6480904d-d79b-484b-9f16-8d5a3eff77e3/kLL9-2.jpg'/>
                <h2>#0012</h2>
            </div>
        </div>
        <div className="page">
            <div>
                <img src='https://uploads.codesandbox.io/uploads/user/6480904d-d79b-484b-9f16-8d5a3eff77e3/s4Ls-3.jpg'/>
                <h2>#003</h2>
            </div>
        </div>
        <div className="page">
            <div>
                <img src='https://uploads.codesandbox.io/uploads/user/6480904d-d79b-484b-9f16-8d5a3eff77e3/s4Ls-3.jpg'/>
                <h2>#004</h2>
            </div>
        </div>
        <div className="page">
            <div>
                <img src='https://uploads.codesandbox.io/uploads/user/6480904d-d79b-484b-9f16-8d5a3eff77e3/Bxsw-5.jpg'/>
                <h2>#005</h2>
            </div>
        </div>
    </div>
}