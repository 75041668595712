import { cn } from '@udecode/cn';
import { AlignJustify, ChevronRight, X } from 'lucide-react';
import { useEffect, useState } from 'react';

import Button from '@/components/Button';
import Logo from '@/components/Logo';

import { usePageStore, type ButtonProps } from '@/stores/page-store';

export default function BaseHeader({ className }: { showLogo?: boolean; buttons?: ButtonProps[]; className?: string }) {
  const { header } = usePageStore();
  const [menuShow, setMenuShow] = useState<boolean>(false);

  useEffect(() => {
    setMenuShow(false);
    console.log('header', header.buttons, header.buttonType);
  }, [header]);

  return (
    <div className={cn('lur-sm backdrop-blur-sm', !header?.visible ? 'hidden' : '', className)}>
      <div className="section flex h-full items-center">
        <div className="relative z-10 flex w-full items-center justify-between">
          <Logo hasTitle={true} />
          {header.buttonType === 'BACK' && header.buttons.length ? (
            <Button onClick={header.buttons[0].onClick}>{header.buttons[0].label}</Button>
          ) : (
            <>
              <div className="hidden items-center gap-6 md:flex">
                {header.buttons?.map((button) => (
                  <Button onClick={button.onClick} key={button.label}>
                    {button.label}
                  </Button>
                ))}
              </div>
              <div className="md:hidden" onClick={() => setMenuShow((pre) => !pre)}>
                {menuShow ? <X strokeWidth={1} size={24} /> : <AlignJustify strokeWidth={1} size={24} />}
              </div>
            </>
          )}
        </div>
        {header.buttonType !== 'BACK' && menuShow && (
          <div className={cn('absolute left-0 top-20 h-screen w-screen bg-[#000000B8] backdrop-blur-sm')}>
            <ul className="flex flex-col gap-16 border-b border-b-[#FFFFFF1F] bg-[#020202] p-6">
              {header.buttons?.map((button) => (
                <li onClick={button.onClick} key={button.label} className="flex items-center justify-between">
                  <span>{button.label}</span> <ChevronRight size={24} strokeWidth={1} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}


