import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { initGA } from './utils/ga';

import Index from './Index';
import Demo from './pages/Demo/Index';

import './styles/index.scss';

initGA();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/doc/*" element={<div>Doc</div>} />
        <Route path="/demo" element={<Demo/>} />

        <Route path="*" element={<Index />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
