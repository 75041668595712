import discordLogo from '@/assets/social/discord-logo.png'
import mediumLogo from '@/assets/social/medium-logo.png'
import telegramLogo from '@/assets/social/telegram-logo.png'
import xLogo from '@/assets/social/x-logo.png'

export const DESIGN_WIDTH = 1280;
export const DESIGN_HEIGHT = 832;

export const DESIGN_CENTER_X = DESIGN_WIDTH / 2;
export const DESIGN_CENTER_Y = DESIGN_HEIGHT / 2;

export const GAME_WINDOW_WIDTH = 1000;
export const GAME_WINDOW_HEADER_HEIGHT = 36;
export const GAME_WINDOW_MAIN_HEIGHT = 600;
export const GAME_WINDOW_FOOTER_HEIGHT = 54;
export const GAME_WINDOW_LEFT = Math.round(DESIGN_WIDTH - GAME_WINDOW_WIDTH) / 2;
export const GAME_WINDOW_RIGHT = Math.round(GAME_WINDOW_LEFT + GAME_WINDOW_WIDTH);
export const GAME_WINDOW_MAIN_TOP = Math.round(DESIGN_HEIGHT - GAME_WINDOW_MAIN_HEIGHT) / 2 - 50;
export const GAME_WINDOW_MAIN_BOTTOM = Math.round(GAME_WINDOW_MAIN_TOP + GAME_WINDOW_MAIN_HEIGHT);

export const GAME_CONTENT_WIDTH = 620;
export const GAME_CONTENT_HEIGHT = 470;
export const GAME_CONTNENT_LEFT = GAME_WINDOW_LEFT + Math.round((GAME_WINDOW_WIDTH - GAME_CONTENT_WIDTH) / 2);
export const GAME_CONTENT_RIGHT = GAME_CONTNENT_LEFT + GAME_CONTENT_WIDTH;
export const GAME_CONTENT_BOTTOM = GAME_WINDOW_MAIN_BOTTOM - 46;

export const GAME_TRIANGLE_WIDHT = 192;
export const GAME_TRIANGLE_HEIGHT = 135;

export const GAME_TRAPEZOID_TOP_WIDTH = GAME_TRIANGLE_WIDHT;
export const GAME_TRAPEZOID_WIDTH = GAME_CONTENT_WIDTH;
export const GAME_TRAPEZOID_HEIGHT = 300;

export const GAME_MOVE_DISTANCE = 276;

export const GAME_OVERLAY_ELEMENT_ID = 'game-overlay';

export const FONT_FAMILY_MAP = {
  'PRESS-START-2P': `"Press Start 2P", serif`,
  'FRAGMENT-MONO': `"Fragment Mono", serif`,
  'PIXELIFY-SANS': `"Pixelify Sans", sans-serif`,
};

// Header
export const EMAIL = 'mailto:hello@xny.ai';
export const CDN_PATH = 'https://s.xny.ai';

//Footer
export const SOCIALS = [
  { name: 'Discord', url: 'https://discord.gg/bVc7cVyzUa', icon: discordLogo },
  { name: 'Telegram', url: ' https://t.me/XnYCommunity', icon: telegramLogo },
  { name: 'X', url: 'https://x.com/XnY_AGI', icon: xLogo },
  {
    name: 'Medium',
    url: 'https://xnynetwork.medium.com',
    icon: mediumLogo,
  },
];
export const PROTOCOLS = [
  { name: 'Litepaper', url: `${CDN_PATH}/static/XnY_Litepaper_v0d93_8f3d7a2e.pdf` },
  { name: 'Documentation', url: 'https://docs.xny.ai/' },
];
export const LITEPAPER_URL = `${CDN_PATH}/static/XnY_Litepaper_v0d93_8f3d7a2e.pdf`;
export const DOCUMENTATION_URL = 'https://docs.xny.ai/';
export const BOOK_URL = `${CDN_PATH}/static/Book_of_XnY_v1d05.pdf`; 
export const ECOSYSTEMS = [
  {
    name: 'Codatta',
    url: 'https://app.codatta.io/',
  },
];

export const GA_TRACKING_ID = 'G-ZZLWSQKPM6';


