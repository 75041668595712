import { useEffect, useRef, useCallback } from 'react';

interface UseAnimationFrameProps {
  duration?: number; // Total duration in milliseconds
  autoStart?: boolean;
  onStart?: () => void; // Callback when animation starts
  onEnd?: () => void; // Callback when animation ends
  onFrame?: (progress: number) => void; // Per-frame callback, progress is a value between 0-1
}

export default function useAnimationFrame({
  duration = 1000,
  onStart,
  onEnd,
  onFrame,
  autoStart = true,
}: UseAnimationFrameProps) {
  const frameRef = useRef<number>();
  const startTimeRef = useRef<number>();

  const animate = useCallback(
    (timestamp: number) => {
      if (!startTimeRef.current) {
        startTimeRef.current = timestamp;
        onStart?.();
      }

      const elapsed = timestamp - startTimeRef.current;
      const progress = Math.min(elapsed / duration, 1);

      onFrame?.(progress);

      if (progress < 1) {
        frameRef.current = requestAnimationFrame(animate);
      } else {
        onEnd?.();
      }
    },
    [duration, onStart, onEnd, onFrame]
  );

  const start = useCallback(() => {
    if (frameRef.current) return;
    startTimeRef.current = undefined;
    frameRef.current = requestAnimationFrame(animate);
  }, [animate]);

  const stop = useCallback(() => {
    if (frameRef.current) {
      cancelAnimationFrame(frameRef.current);
      frameRef.current = undefined;
    }
  }, []);

  // Cleanup
  useEffect(() => {
    if (autoStart) {
      start();
    }
    return () => {
      stop();
    };
  }, [autoStart, stop]);

  return { start, stop };
}
