import { cn } from '@udecode/cn'
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'framer-motion';

import Footer from '@/components/page-0/Footer';
import Logo3D from '@/components/page-0/Logo3D';
import { setPageHeader, updatePage } from '@/stores/page-store';

import Loading1 from '@/assets/home/loading_1.png';
import Loading2 from '@/assets/home/loading_2.png';
import Loading3 from '@/assets/home/loading_3.png';
import Loading4 from '@/assets/home/loading_4.png';

import useAnimationFrame from '@/hooks/useAnimationFrame';
import { trackPageView } from '@/utils/ga';

export default function Page({ className }: { className?: string }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.3 });
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (loaded && isInView) {
      console.log('updatePage', loaded);

      updatePage(0);
      setPageHeader({ visible: true, buttonType: 'ALL' });
      trackPageView('page-home');
    }
  }, [isInView, loaded]);

  return (
    <div className={cn('m-auto flex h-screen flex-col items-center justify-between', className)} ref={ref}>
      <div className="relative w-full flex-1">
        <Logo3D
          className={cn(
            'absolute left-0 top-0 transition-all duration-1000 ease-in-out',
            loaded ? 'opacity-1' : 'opacity-0'
          )}
        />
        <Loading className="pointer-events-none absolute left-0 top-0" onLoad={() => setLoaded(true)} />
      </div>
      <Footer className={loaded ? 'visible' : 'invisible'} />
    </div>
  );
}

function Loading({ className, onLoad }: { className?: string; onLoad?: () => void }) {
  const [progress, setProgress] = useState<number>(0);

  useAnimationFrame({
    duration: 3000,
    onFrame: (progress: number) => {
      setProgress(progress);

      if (progress === 1) {
        onLoad?.();
      }
    },
  });

  const loadingImg = useMemo(() => {
    if (progress < 0.25) return Loading1;
    if (progress < 0.5) return Loading2;
    if (progress < 0.75) return Loading3;
    return Loading4;
  }, [progress]);

  return (
    <div
      className={cn(
        'opacity-1 flex h-full w-full items-center justify-center transition-all duration-1000 ease-in-out',
        progress === 1 ? 'opacity-0' : '',
        className
      )}
    >
      <div className="relative h-[300px] w-[300px] rounded-full">
        <>
          <img src={loadingImg} className="mx-[80px] my-[80px] block h-[140px] w-[140px]" />
          <svg className="absolute left-0 top-0 h-full w-full">
            <circle cx="150" cy="150" r="149" fill="none" stroke="#FFFFFF33" strokeWidth="1" />
            <circle
              cx="150"
              cy="150"
              r="149"
              fill="none"
              strokeWidth="1"
              strokeDasharray="942"
              strokeDashoffset={(1 - progress) * 942}
              stroke="url(#gradient)"
              transform="rotate(-90 150 150)"
            />
            <defs>
              <linearGradient id="gradient">
                <stop offset="0%" stopColor="#FCC800" />
                <stop offset="100%" stopColor="#FFFFFF" />
              </linearGradient>
            </defs>
          </svg>
        </>

        <div className="pt-[40px] text-center text-base text-[#D7D8CE]">{(progress * 100).toFixed(1)}%</div>
      </div>
    </div>
  );
}
