import { useEffect, useMemo, useRef } from 'react';
import { cn } from '@udecode/cn';
import { easeInOut, motion, useInView, useScroll, useTransform } from 'framer-motion';
import { useWindowSize } from 'usehooks-ts';

import TextScramble from '@/utils/textScramble';

import { pageActions } from '@/stores/page-store';
import PageDownButton from '@/components/PageDownButton';

export default function Footer({ className }: { className?: string }) {
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: '0px 0px 200px 0px' });
  const phrases = ['Assetifying Frontier Data', 'for AGI and DeSci'];

  const animateText = () => {
    const el1 = document.querySelector('#phrase1');
    const el2 = document.querySelector('#phrase2');
    const fx1 = new TextScramble(el1);
    const fx2 = new TextScramble(el2);

    const next = () => {
      fx1.setText(phrases[0]).then(() => {
        timer.current = setTimeout(next, 4000);
      });
      fx2.setText(phrases[1]);
    };
    next();
  };

  useEffect(() => {
    animateText();

    return clearTimeout(timer.current);
  });

  useEffect(() => {
    console.log('inView', isInView);
    if (isInView) {
      pageActions.updatePage(0);
    } else {
      pageActions.updatePage(1);
    }
  }, [isInView]);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['end end', 'start start'],
  });
  const scaleX = useTransform(scrollYProgress, [0, 1], [1, 0.9], { ease: easeInOut });
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0], { ease: easeInOut });

  return (
    <motion.div
      className={cn('mb-7 flex w-full flex-col items-center justify-between overflow-hidden md:flex-row', className)}
      ref={ref}
      style={{ scaleX: scaleX, opacity: opacity }}
    >
      <div className="w-full md:w-3/4">
        <p className="max-w-full text-nowrap text-center text-lg text-[#FFFFFF] md:text-left md:text-[#D7D8CE]">
          <span id="phrase1">{phrases[0]}</span>
          <br />
          <span id="phrase2">{phrases[1]}</span>
        </p>
        <p className="mt-3 text-center text-[#999999] sm:text-left">
          Secured $2.5M in a seed round led by <span className="text-[#FFFFFF]">OKX Ventures</span>, joined by renowned
          venture funds.
        </p>
      </div>
      <div className="mt-[110px] flex h-full">
        <PageDownButton page={1} />
      </div>
    </motion.div>
  );
}
