import {  useRef, useState } from 'react';
import { cn } from '@udecode/cn';
import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { Center } from '@react-three/drei';
import useSpline from '@splinetool/r3f-spline';

import { usePageStore } from '@/stores/page-store';
import { CDN_PATH } from '@/config';
import { useWindowSize } from 'usehooks-ts';

export default function Logo3D({ className }: { className?: string }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const { width } = useWindowSize();

  return (
    <div className={cn('relative h-full w-full', className)}>
      <Canvas shadows flat linear camera={{ position: [0, 0, 550], fov: 50 }}>
        <ambientLight intensity={0.5} />
        <directionalLight position={[0, 10, 5]} />
        <Logo onLoaded={() => setIsLoaded(true)} />
      </Canvas>
      {
        <div
          className={cn(
            'ease-bounce pointer-events-none absolute left-0 top-0 h-full w-full bg-center bg-no-repeat transition-all duration-300',
            isLoaded ? 'scale-130 opacity-0' : 'scale-100 opacity-100'
          )}
          style={{
            backgroundImage: `url(/logo-3d.png)`,
            backgroundSize: '50% auto',
          }}
        ></div>
      }
    </div>
  );
}

function Logo({ onLoaded }: { onLoaded: () => void }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const { nodes, materials } = useSpline(`${CDN_PATH}/static/559ef5725e2ccbdc7af0ce4058450c719062a305.splinecode`);
  const meshRef = useRef<THREE.Mesh>(null);
  const groupRef = useRef<THREE.Group>(null);
  const { isMobile } = usePageStore();

  useFrame((state) => {
    if (nodes && materials && !isLoaded) {
      setIsLoaded(true);
      onLoaded();
    }

    if (groupRef.current && !isMobile) {
      // Calculate mouse position vector relative to canvas center
      const mouseX = state.pointer.x;
      const mouseY = state.pointer.y;

      // Limit tilt angle to 10 degrees (convert to radians)
      const maxTilt = THREE.MathUtils.degToRad(10);

      // Calculate target rotation angle, using clamp to limit between -10 and 10 degrees
      const targetRotationX = THREE.MathUtils.clamp(-mouseY * maxTilt, -maxTilt, maxTilt);
      const targetRotationY = THREE.MathUtils.clamp(mouseX * maxTilt, -maxTilt, maxTilt);

      // Create target quaternion
      const targetQuaternion = new THREE.Quaternion();
      targetQuaternion.setFromEuler(new THREE.Euler(targetRotationX, targetRotationY, 0, 'XYZ'));

      // Smoothly interpolate current rotation to target rotation
      groupRef.current.quaternion.slerp(targetQuaternion, 0.1);
    }
  });

  return (
    <Center>
      <group position={[-80, 75, 0]} scale={0.95} ref={groupRef}>
        <mesh
          name="Shape 0"
          geometry={nodes['Shape 0'].geometry}
          material={materials['Shape 0 Material']}
          castShadow
          receiveShadow
          position={[0, 0, 0]}
          ref={meshRef}
        />
      </group>
      <hemisphereLight name="Default Ambient Light" intensity={0.75} color="#eaeaea" />
    </Center>
  );
}
