import { cn } from '@udecode/cn';

export default function Button({
  children,
  className,
  onClick,
}: {
  children;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <button
      className={cn('rounded-[4px] border border-white px-3 text-sm leading-[30px]', className)}
      onClick={(e) => {
        e.preventDefault();
        onClick?.();
      }}
    >
      {children}
    </button>
  );
}
