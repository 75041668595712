import { cn } from '@udecode/cn';
import scroll from '@/utils/scroll';

export default function PageDownButton({ className, page }: { className?: string; page: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={cn(
        'block h-10 w-10 cursor-pointer text-[#999999] transition-transform hover:translate-y-1',
        className
      )}
      onClick={() => scroll.scrollToPage(page)}
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M12 8v8" />
      <path d="m8 12 4 4 4-4" />
    </svg>
  );
}
