function scrollToPage(page: number) {
  window.scrollTo({
    top: window.innerHeight * page,
    behavior: 'smooth',
  });
}

export default {
  scrollToPage,
};
