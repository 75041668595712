import { proxy, useSnapshot } from 'valtio';

import scroll from '@/utils/scroll';
import { EMAIL, LITEPAPER_URL, DOCUMENTATION_URL, BOOK_URL } from '@/config';
import { TRACK_CATEGORY, trackEvent } from '@/utils/ga';

export interface ButtonProps {
  label: string;
  onClick?: () => void;
}

interface PageState {
  page: number;
  header: {
    visible?: boolean;
    buttonType?: string;
    buttons?: ButtonProps[];
  };
  isMobile: boolean;
  win: {
    width: number;
    height: number;
  };
}

type HeaderButtonType = 'EMPTY' | 'ALL' | 'BACK';

const backButtons = [
  {
    label: 'Back To Home',
    onClick: () => {
      trackEvent(TRACK_CATEGORY.NAV_CLICK, {
        contentType: 'back-home',
      });
      scroll.scrollToPage(0);
    },
  },
];
const allButtons = [
  {
    label: 'Our Vision',
    onClick: () => {
      trackEvent(TRACK_CATEGORY.NAV_CLICK, {
        contentType: BOOK_URL,
      });
      window.open(BOOK_URL, '_blank');
    },
  },
  {
    label: 'Litepaper',
    onClick: () => {
      trackEvent(TRACK_CATEGORY.NAV_CLICK, {
        contentType: LITEPAPER_URL,
      });
      window.open(LITEPAPER_URL, '_blank');
    },
  },
  {
    label: 'Documentation',
    onClick: () => {
      trackEvent(TRACK_CATEGORY.NAV_CLICK, {
        contentType: DOCUMENTATION_URL,
      });
      window.open(DOCUMENTATION_URL, '_blank');
    },
  },
  {
    label: 'Contact',
    onClick: () => {
      trackEvent(TRACK_CATEGORY.NAV_CLICK, {
        contentType: EMAIL,
      });
      window.location.href = EMAIL;
    },
  },
];
const initialState: PageState = {
  page: -1,
  header: {
    visible: false,
    buttonType: 'ALL',
    get buttons() {
      if (this.buttonType === 'EMPTY') return pageStore.isMobile ? allButtons : [];
      if (this.buttonType === 'BACK') return backButtons;

      return allButtons;
    },
  },
  get isMobile() {
    return window.innerWidth < 640;
  },
  get win() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
};

const pageStore = proxy<PageState>(initialState);

export function updatePage(page: number) {
  pageStore.page = page;
}

export function setPageHeader({ visible, buttonType = 'ALL' }: { visible?: boolean; buttonType?: HeaderButtonType }) {
  if (visible !== undefined) {
    pageStore.header.visible = visible;
  }

  if (buttonType !== undefined) {
    pageStore.header.buttonType = buttonType;
  }
}

export const usePageStore = () => useSnapshot(pageStore);

export const pageActions = {
  updatePage,
  setPageHeader,
};
